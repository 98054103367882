import React from "react";
import ringtone from "../../../../Assets/audio/3108_call-sound.ru__.mp3";
import attendantes from "../../../../Assets/IconesChat/Attendants.svg";
import * as OperatorService from "../../../../Services/operator";
import icon from "../../../../Assets/icon/logo_ba_font_02.png";
import {useHistory, useLocation} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import NoMessages from "../../NoMessages";
import TabContext from "@material-ui/lab/TabContext";
import SearchIcon from "@material-ui/icons/Search";
import Snackbar from "@material-ui/core/Snackbar";
import ChatOperatorList from "../../ChatOperatorList";
import {useTranslation} from "react-i18next";
import isMobile from "../../../../Services/isMobile";
import TabPanel from "@material-ui/lab/TabPanel";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import ContactsList from "../../ContactList";
import Grid from "@material-ui/core/Grid";
import NewContact from "../../NewContact";
import WS from "../../../../ws";
import moment from "moment";
import {LightTooltip} from "../../../../Styles/styles";
import Accordion from "@material-ui/core/Accordion";
import MuiAlert from "@material-ui/lab/Alert";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChatListItem from "../../ChatListItem";
import {useChat} from "../../../../Providers/chat";
import DrawerSectorSelect from "../SelctSector";
import {ReactComponent as BackArrow} from "../../../../Assets/IconesChat/Back Arrow.svg";
import * as ChatFuncion from "../../../../Services/chatFunctions";

import {
    Archived,
    Attendents,
    Botton,
    BottonContacts,
    Config,
    Contacts,
    Container,
    Search,
    SearchInput,
    SmallSuport,
    TabListStyled,
    TabStyled,
    Top,
    useStyles,
} from "./styles";
import ImportContact from "../../ImportContacts";
import Drawer from "../Drawer";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ChatAsideMobile = () => {

    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();


    const mobile = isMobile();
    var windowHeight = window.innerHeight;

    function recarregarPagina() {
        history?.push("/attendant/chat");
    }

    var TimeOut;
    window.onresize = function () {
        clearTimeout(TimeOut);
        TimeOut = setTimeout(recarregarPagina, 10);
    };

    const deadline = moment().add(-1, "minute");

    const {chat, setChat, listSectors, setListSectors} = useChat();


    const bottomContacts = React.useRef(null);
    const bottomAtendants = React.useRef(null);
    const bottomArchived = React.useRef(null);

    const [notificationSound, setNotification] = React.useState(true);
    const [AttendantsOpen, setAttendantsOpen] = React.useState(false);
    const [contactsOpen, setContactsOpen] = React.useState(false);
    const [ArchivedOpen, setArchivedOpen] = React.useState(false);
    const [searchStatus, setSearchStatus] = React.useState(false);
    const [searchStatus2, setSearchStatus2] = React.useState(false);

    const [chatsFinished, setChatsFinished] = React.useState([]);
    const [chatsOthers, setChatsOthers] = React.useState([]);
    const [operators, setOperators] = React.useState([]);
    const [contacts, setContacts] = React.useState([]);
    const [sectorts, setSectors] = React.useState([]);
    const [chats, setChats] = React.useState([]);

    const [newmessage, setNewMessage] = React.useState(0);

    const [searchFinished, setSearchFinished] = React.useState("");
    const [searchContact, setSearchContact] = React.useState("");
    const [placeholder, setPlaceholder] = React.useState("");
    const [search, setSearch] = React.useState("");
    const [notifySector, setNotifySector] = React.useState("");

    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setNewMessage(0);
    };

    /* set ActiveSector */
    const setSectorActive = (id, label) => {
        setChat({sectorName: label, sectorID: id});
        localStorage.setItem("chat", "null");
        console.log(chat.sectorName);

        if (value === "1") {
            getListMyAttendments(id);
        } else {
            getListOthers(id);
        }
        setPlaceholder(label);
    };

    const loadContacts = () => {
        setContactsOpen(!contactsOpen);
        getListMyContacts();
    };
    const loadAttendantsOpen = () => {
        getOperator();
        setAttendantsOpen(!AttendantsOpen);
    };
    const loadArchived = () => {
        setArchivedOpen(!ArchivedOpen);
        getListMyAttendmentsFinished(chat.sectorID);
    };
    /* Get Contatos */
    const getListMyContacts = async () => {
        const listCont = await ChatFuncion.getContacts();
        setContacts(listCont);
    };
    /* Get Sectors */
    const getSector = async () => {
        try {
            if (!chat.sectorID) {
                const sectorList = await ChatFuncion.getDepartaments();
                setSectors(sectorList);
                setListSectors(sectorList);
                setChat({sectorName: sectorList["0"]?.name, sectorID: sectorList["0"]?.id});
                getListMyAttendments(sectorList["0"]?.id);
                setPlaceholder(sectorList["0"]?.name);
            } else {
                setPlaceholder(chat.sectorName);
                const sectorList = await ChatFuncion.getDepartaments();
                setSectors(sectorList);
            }
        } catch (error) {

        }
    };
    /* Get Chats */
    const getListMyAttendments = async (id) => {
        const listMyAttend = await ChatFuncion.getMyAttendsList(id);
        if (listMyAttend === "error") {
            setOpenError(true);
        } else {
            setChats(listMyAttend);
        }
    };
    /* Get Others Attendance */
    const getListOthers = async (id) => {
        const listOtherAttends = await ChatFuncion.getOthersAttensList(id);
        if (listOtherAttends === "error") {
            setOpenError(true);
        } else {
            setChatsOthers(listOtherAttends);
        }
    };

    const getListMyAttendmentsFinished = async (id) => {
        const listAttensFineshd = await ChatFuncion.getMyAttendsFinished(id);
        if (listAttensFineshd === "error") {
            setOpenError(true);
        } else {
            setChatsFinished(listAttensFineshd);
        }
    };

    const getOperator = async () => {
        const operatorsList = await ChatFuncion.getOperatorsList(chat.sectorID);
        if (operatorsList === "error") {
            setOpenError(true);
        } else {
            setOperators(operatorsList);
        }
    };

    const [open3, setOpen3] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const handleCloseError = () => {
        setOpenError(!openError);
    };

    const handleClose3 = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen3(false);
    };

    React.useEffect(() => {
        if (localStorage.getItem("updated") === "true") {
            getListMyContacts();
            getListMyAttendments(chat.sectorID);
            localStorage.setItem("updated", "false");
        }
        if (chat.length === 0) {
            getListMyAttendments(chat.sectorID);
        }
    }, [location, chat]);

    React.useEffect(() => {
            if (sectorts.length <= 0) {
                getSector();
            }
        }, [sectorts.length],
    );

    React.useEffect(() => {
        if (localStorage.getItem("chat") !== "null") {
            getListMyAttendments(chat.sectorID);
        }
    }, [localStorage.getItem("chat")]);

    const turnNotification = () => {
        setNotification(!notificationSound);
        history.push("/attendant/chat");
    };
    const logout = () => {
        OperatorService.logout();
    };
    const notify = () => {
        Notification.requestPermission();
    };

    React.useEffect(() =>
        WS.subscribe((msg) => {

            if (msg.subject === "message.received") {
                if (msg.data?.department_id === parseInt(chat.sectorID)) {
                    document.getElementById("notify")?.play()
                        .catch(err => console.warn(err));
                    if (value === "1") {
                        /* verificar como fazer */
                    } else {
                        setNewMessage(1);
                    }
                }
            } else if (msg.subject === "attendance.created" || msg.subject === "attendance.finished" || msg.subject === "attendance.transferred") {
                if (msg.data?.department_id === parseInt(chat.sectorID)) {
                    document.getElementById("notify")?.play()
                        .catch(err => console.warn(err));
                    if (value === "1") {
                        getListMyAttendments(chat.sectorID);
                    } else {
                        setNewMessage(1);
                        getListOthers(chat.sectorID);
                    }

                }
            }
            if (msg.data?.department_id !== chat.sectorID && msg.data.operator_id === parseInt(localStorage.getItem("operator"))) {
                if (msg.subject === "message.received" || msg.subject === "attendance.created") {
                    if (mobile) {
                        document.getElementById("notify")?.play();
                    } else {
                        const found = sectorts.find(sector => sector.id === msg.data?.department_id);
                        setNotifySector(found?.name);
                        setOpen3(true);
                        new Notification(found?.name ? found?.name : t("words.new_msg"), {
                            body: `${t("words.new_msg_sector")} ${found?.name}`,
                            icon: {icon},
                        });
                    }
                }
            }
        }), [chat, value]);

    const searchFunc = (set) => {
        set(true);
        setTimeout(() => {
            const text = document.getElementById("search");
            text?.focus();
        }, 100);
    };
    return (
        <Container ref={bottomArchived}>
            {notificationSound === true && <audio id="notify" src={ringtone} preload="auto" hidden></audio>}

            <Contacts style={{width: contactsOpen ? "100%" : "0px", height: contactsOpen ? "100%" : "0px"}}>
                {searchStatus2 === true ?
                    <Top id="btnArea">
                        <div style={{paddingRight: "20px"}} onClick={() => setSearchStatus2(false)}>
                            <BackArrow/>
                        </div>
                        <SearchInput>
                            <input type="search" placeholder={t("words.search")} id="search"
                                   onChange={e => setSearchContact(e.target.value)}/>
                        </SearchInput>
                    </Top> :
                    <Top id="btnArea" style={{justifyContent: "space-between"}}>
                        <div style={{display: "flex"}}>
                            <div style={{paddingRight: "20px"}} onClick={() => setContactsOpen(false)}>
                                <BackArrow onClick={() => setAttendantsOpen(false)}/>
                            </div>
                            <SmallSuport>{t("words.contacts_list")}</SmallSuport>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                        }}>
                            <Config style={{paddingRight: "22px"}} onClick={() => searchFunc(setSearchStatus2)}>
                                <LightTooltip title={t("words.search")} placement="top">
                                    <SearchIcon style={{color: "#434343"}}/>
                                </LightTooltip>
                            </Config>
                            <Config>
                                <LightTooltip title={t("words.settings")} placement="top">
                                    <Drawer operators={loadAttendantsOpen}
                                            contacts={loadContacts}
                                            finishedList={loadArchived}
                                            notifySound={notificationSound}
                                            notifySoundFunc={turnNotification}
                                            notifyAlert={notify}
                                            logoutClick={logout}
                                    />
                                </LightTooltip>
                            </Config>
                        </div>
                    </Top>
                }
                <Search style={{padding: "20px 20px", background: "#F7F8FA"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} style={{textAlign: "center "}}>
                            <ImportContact who="new"/>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: "center "}}>
                            <NewContact who="new"/>
                        </Grid>
                    </Grid>
                </Search>
                <div style={{padding: "5px 15px"}}>
                    <div style={{
                        height: windowHeight <= 1000 ? mobile ? `calc(${windowHeight}px - 150px)` : `calc(${windowHeight}px - 235px)` : `calc(${windowHeight}px - 300px)`,
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #E1E5EB",
                        borderRadius: "6px",
                        opacity: "1",
                        overflow: "scroll",
                    }}>
                        {contacts.length === 0 ? <NoMessages text={t("words.no_contacts")}/>
                            :
                            <BottonContacts ref={bottomContacts}>

                                {contacts.filter((contact) => {
                                    if (searchContact === "") {
                                        return contact;
                                    } else if (contact.name.toLowerCase().includes(searchContact.toLowerCase()) || contact.jid.toLowerCase().includes(searchContact.toLowerCase())) {
                                        return contact;
                                    } else {
                                        return null;
                                    }
                                }).map(contact => (
                                    <ContactsList key={contact.id} name={contact.name ? contact.name : "noName"}
                                                  phone={contact?.jid}
                                                  id={contact?.id} image={contact?.image}
                                    >
                                        <NewContact who="edit" id={contact?.id} open="true"/>
                                    </ContactsList>
                                ))}

                            </BottonContacts>}
                    </div>
                </div>
            </Contacts>

            <Attendents style={{width: AttendantsOpen ? "100%" : "0px", height: AttendantsOpen ? "100%" : "0px"}}>
                <Top id="btnArea" style={{justifyContent: "space-between"}}>
                    <div style={{display: "flex"}}>
                        <div style={{paddingRight: "20px"}} onClick={() => setSearchStatus(false)}>
                            <BackArrow onClick={() => setAttendantsOpen(false)}/>
                        </div>
                        <SmallSuport>{t("words.operators")}</SmallSuport>
                    </div>
                    <Config>
                        <LightTooltip title={t("words.settings")} placement="top">
                            <Drawer operators={loadAttendantsOpen}
                                    contacts={loadContacts}
                                    finishedList={loadArchived}

                                    notifySound={notificationSound}
                                    notifySoundFunc={turnNotification}
                                    notifyAlert={notify}
                                    logoutClick={logout}
                            />
                        </LightTooltip>
                    </Config>
                </Top>
                {operators.length === 0 ? <NoMessages text={t("words.no_operators_registered")}/>
                    :
                    <BottonContacts style={{
                        height: windowHeight <= 900 ? mobile ? `calc(${windowHeight}px - 160px)` : `calc(${windowHeight}px - 160px)` : `calc(${windowHeight}px - 165px)`,
                        padding: "10px 10px",
                        background: "#F7F8FA",
                    }}>
                        <div className={classes.root}>
                            <Accordion className={classes.aco}>
                                <AccordionSummary
                                    className={classes.border}
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}><img src={attendantes} alt=""
                                                                                 onClick={loadAttendantsOpen}
                                                                                 style={{paddingRight: "10px"}}/> {t("words.online_operators")}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                                {operators.map(operator => (
                                    (moment(operator.logged_at) >= deadline &&
                                        <ChatOperatorList key={operator.id} name={operator?.name} type={operator.type}
                                                          id={operator?.id} image={operator?.image}
                                                          logged_at={operator.logged_at}/>
                                    )))}
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading}><img src={attendantes} alt=""
                                                                                 onClick={loadAttendantsOpen}
                                                                                 style={{paddingRight: "10px"}}/> Atendentes
                                        Offline </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                                {operators.map(operator => (
                                        (operator.logged_at ? moment(operator.logged_at) <= deadline &&
                                            <ChatOperatorList key={operator.id} name={operator?.name} id={operator?.id}
                                                              type={operator.type} image={operator?.image}
                                                              logged_at={operator.logged_at}/> :
                                            <ChatOperatorList logged_at={"never_logged"} key={operator.id}
                                                              name={operator?.name} type={operator.type} id={operator?.id}
                                                              image={operator?.image}/>)
                                    ),
                                )}
                            </Accordion>
                        </div>
                    </BottonContacts>
                }
            </Attendents>

            <Archived style={{width: ArchivedOpen ? "100%" : "0px", height: ArchivedOpen ? "100%" : "0px"}}>
                <Top id="btnArea" style={{justifyContent: "space-between"}}>

                    <div style={{display: "flex"}}>
                        <div style={{paddingRight: "20px"}} onClick={() => setSearchStatus(false)}>
                            <BackArrow onClick={() => setArchivedOpen(false)}/>
                        </div>
                        <SmallSuport>{t("words.finished_att")}</SmallSuport>
                    </div>
                    <Config>
                        <LightTooltip title={t("words.settings")} placement="top">
                            <Drawer operators={loadAttendantsOpen}
                                    contacts={loadContacts}
                                    finishedList={loadArchived}

                                    notifySound={notificationSound}
                                    notifySoundFunc={turnNotification}
                                    notifyAlert={notify}
                                    logoutClick={logout}
                            />
                        </LightTooltip>
                    </Config>
                </Top>
                <Search>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SearchInput>
                                <SearchIcon style={{color: "#434343"}}/>
                                <input type="search" placeholder={t("words.search")}
                                       onChange={e => setSearchFinished(e.target.value)}/>
                            </SearchInput>
                        </Grid>
                    </Grid>
                </Search>

                {chatsFinished.length === 0 ? <NoMessages text={t("words.no_finished_att")}/>
                    :
                    <BottonContacts>
                        {chatsFinished.filter((finished) => {
                            if (search === "") {
                                return finished;
                            } else if (finished?.contact?.name.toLowerCase().includes(searchFinished.toLowerCase()) || finished?.contact.jid.toLowerCase().includes(searchFinished.toLowerCase())) {
                                return finished;
                            } else {
                                return null;
                            }
                        }).map(finished => (
                            <ChatListItem
                                key={finished?.id}
                                OperadorName={finished?.operator.name}
                                name={finished?.contact.name}
                                phone={finished?.contact.jid}
                                date={finished?.contact.updated_at}
                                attendenceId={finished?.id}
                                id={finished?.contact.id} image={finished?.contact.image}/>
                        ))}
                    </BottonContacts>
                }
            </Archived>

            {searchStatus === true ?
                <Top id="btnArea">
                    <div style={{paddingRight: "20px"}} onClick={() => setSearchStatus(false)}>
                        <BackArrow/>
                    </div>
                    <SearchInput>
                        <input type="text" placeholder={t("words.search")} id="search"
                               onChange={e => setSearch(e.target.value)} value={search}/>
                    </SearchInput>
                </Top> :
                <Top id="btnArea" style={{justifyContent: "space-between"}}>
                    <SmallSuport>{placeholder}</SmallSuport>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                    }}>
                        <Config style={{paddingRight: "22px"}} onClick={() => searchFunc(setSearchStatus)}>
                            <LightTooltip title={t("words.search")} placement="top">
                                <SearchIcon style={{color: "#434343"}}/>
                            </LightTooltip>
                        </Config>
                        <Config>
                            <LightTooltip title={t("words.sector")} placement="top">
                                <DrawerSectorSelect sectorList={listSectors} selctFunc={setSectorActive}/>
                            </LightTooltip>
                        </Config>
                        <Config>
                            <LightTooltip title={t("words.settings")} placement="top">
                                <Drawer operators={loadAttendantsOpen}
                                        contacts={loadContacts}
                                        finishedList={loadArchived}
                                        notifySound={notificationSound}
                                        notifySoundFunc={turnNotification}
                                        notifyAlert={notify}
                                        logoutClick={logout}
                                />
                            </LightTooltip>
                        </Config>
                    </div>
                </Top>
            }

            <TabContext value={value}>
                <AppBar position="static" className={classes.AppBarStyle}>
                    <TabListStyled indicatorColor="primary" style={{padding: "0 20px"}} onChange={handleChange}
                                   aria-label="Calls">
                        <TabStyled
                            label={<Badge badgeContent={newmessage} style={{paddingRight: "5px", fontFamily: "Mulish"}}
                                          color="error"> My calls </Badge>} value="1"
                            className={value === "1" && classes.AppTab}
                            onClick={() => getListMyAttendments(chat.sectorID)}/>
                        <TabStyled label={<Badge badgeContent={0} style={{paddingRight: "5px", fontFamily: "Mulish"}}
                                                 color="primary"> Others </Badge>} value="2"
                                   className={value === "2" && classes.AppTab}
                                   onClick={() => getListOthers(chat.sectorID)}/>
                    </TabListStyled>
                </AppBar>
                <Botton value="1" ref={bottomAtendants} id="bottom">

                    {chats.length === 0 ? <TabPanel value="1" className={classes.TabPanelStyle}><NoMessages
                            text={t("words.no_att")}/></TabPanel>
                        :
                        <TabPanel value="1" className={classes.TabPanelStyle}>

                            {chats.filter((chat) => {
                                if (search === "") {
                                    return chat;
                                } else if (chat?.contact?.name.toLowerCase().includes(search.toLowerCase()) || chat?.contact.jid.toLowerCase().includes(search.toLowerCase())) {
                                    return chat;
                                } else {
                                    return null;
                                }
                            }).map(chat => (
                                <ChatListItem
                                    name={chat?.contact.name}
                                    OperadorName={chat?.operator.name}
                                    key={chat?.contact.id}
                                    phone={chat?.contact.jid}
                                    date={chat?.contact.updated_at}
                                    attendenceId={chat?.id}
                                    operatorId={chat?.operator_id}
                                    lastMessage={chat.last_message}
                                    id={chat?.contact.id} image={chat?.contact.image}/>
                            ))}
                        </TabPanel>
                    }

                    {chatsOthers.length === 0 ? <TabPanel value="2" className={classes.TabPanelStyle}><NoMessages
                            text={t("words.no_other_att")}/></TabPanel>
                        :
                        <TabPanel value="2" className={classes.TabPanelStyle}>
                            {chatsOthers.filter((otherChat) => {
                                if (search === "") {
                                    return otherChat;
                                } else if (otherChat?.contact?.name.toLowerCase().includes(search.toLowerCase()) || otherChat?.contact.jid.toLowerCase().includes(search.toLowerCase())) {
                                    return otherChat;
                                } else {
                                    return null;
                                }
                            }).map(otherChat => (
                                <ChatListItem key={otherChat?.contact.id} name={otherChat?.contact.name}
                                              OperadorName={otherChat?.operator.name}
                                              phone={otherChat?.contact.jid}
                                              date={otherChat?.contact.updated_at}
                                              attendenceId={otherChat?.id}
                                              operatorId={otherChat?.operator_id}
                                              lastMessage={chat.last_message}
                                              id={otherChat?.contact.id} image={otherChat?.contact.image}/>
                            ))}
                        </TabPanel>
                    }

                </Botton>
            </TabContext>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open3}
                autoHideDuration={6000}
                onClose={handleClose3}>
                <Alert onClose={handleClose3} style={{background: "#476BFF"}} severity="info" onClick={handleClose3}>
                    {t("words.new_msg_sector")} - {notifySector}
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={openError}
                autoHideDuration={3000}
                onClose={handleCloseError}>
                <Alert onClose={handleCloseError} style={{background: "#ff4747"}} severity="error"
                       onClick={handleCloseError}>
                    {t("words.something_wrong")}
                </Alert>
            </Snackbar>

        </Container>
    );
};

export default ChatAsideMobile;