import React from "react";
import {Input, Modal, ModalFooter, ModalBody} from "reactstrap";
import PropTypes from "prop-types";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import {Button, Typography, Snackbar} from "@material-ui/core";
import {saveBtn2, BtnBlue2, ButtonBlue} from "../../../Styles/buttons";
import axios_base from "../../../axios_base";
import Select from "react-select";
import MuiAlert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import ReactDOM from "react-dom";
import {Link} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import Illustration_Attention from "../../../Assets/icon/Illustration_Attention.png";
import IllustrationLoadingtheSetting from "../../../Assets/CommunIcons/Illustration  Loading the Setting.svg";
import Close from "../../../Assets/attendance/Cloese.svg";
import InfoStepCard from "../InfoStepCard";
import {ReactComponent as CameraIcon} from "../../../Assets/icon/Camera.svg";
import {ReactComponent as GroupIcon} from "../../../Assets/icon/icon Group.svg";
import InfoTooltip from "../../../Common/Components/InfoTooltip";
import * as CampaignFunctions from "../../../Services/campaignFunctions";
import IconCampaignSetting from "../../../Assets/CommunIcons/Icon Campaign Setting.svg";
import CreateGroupStepbystepWite from "../../../Assets/CommunIcons/Create Group  Step by step Wite.svg";
import GroupSettingStepbystepWite from "../../../Assets/CommunIcons/Group Setting  Step by step Wite.svg";
import Cloese from "../../../Assets/CommunIcons/Cloese.svg";
import LiquidLoader from "../../../Animations/LiquidLoader";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CodeIcon from "@material-ui/icons/Code";
import FormGroup from "@material-ui/core/FormGroup";

import {
    Container,
    ColorlibConnector,
    useQontoStepIconStyles,
    useColorlibStepIconStyles,
    PrettoSlider,
    Label,
    Top,
    TopBar,
    Body,
    BodyCard,
    Div,
    DivStep,
    Colunm1,
    Colunm2,
    TextTitle,
    Line,
    BoxViewNumber,
    BlueCheckbox,
} from "./style";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /*** Whether this step is active.*/
    active: PropTypes.bool,
    /*** Mark the step as completed. Is passed to child components.*/
    completed: PropTypes.bool,
};

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;

    const icons = {
        1: <img alt="" src={IconCampaignSetting}/>,
        2: <img alt="" src={CreateGroupStepbystepWite}/>,
        3: <img alt="" src={GroupSettingStepbystepWite}/>,
        4: <CodeIcon/>,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /*** Whether this step is active.*/
    active: PropTypes.bool,
    /** * Mark the step as completed. Is passed to child components.*/
    completed: PropTypes.bool,
    /** * The label displayed in the step icon.*/
    icon: PropTypes.node,
};


const StepByStep = (props) => {
    const {
        buttonLabel,
        action,
        campaignInfo,
        leadsInfo,
        campaignNum,
        id,
        reload,
        numberStatus,
    } = props;


    const {t} = useTranslation();
    const BtnSave = saveBtn2();
    const btn = BtnBlue2();
    const history = useHistory();

    const [num, setNum] = React.useState("");
    const [campaignName, setName] = React.useState("");
    const [GruoupName, setNameGroup] = React.useState("");
    const [facebookPixelId, setFacebookPixelId] = React.useState("");
    const [facebookPixelEventName, setFacebookPixelEventName] = React.useState("");


    const [open, setOpen] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [modal2, setModal2] = React.useState(false);
    const [modal3, setModal3] = React.useState(false);
    const [errorSize, setErrorSize] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openErrorLength, setOpenErrorLength] = React.useState(false);
    const [openError2, setOpenError2] = React.useState(false);
    const [openCreating, setOpenCreate] = React.useState(false);
    const [hasAccessTag, setHasAccessTag] = React.useState(false);
    const [valuePermission, setPermision] = React.useState(false);
    const [facebookPixel, setFacebookPixel] = React.useState(false);
    const [errorDescription, setErrorDescription] = React.useState(false);
    const [disconnectedEdit, setDisconnectedEdit] = React.useState(false);
    const [modalChangeNumber, setModalChangeNumber] = React.useState(false);
    const [modaEdit, setModaEdit] = React.useState(false);

    const [hasAccess, setHasAccess] = React.useState("");


    const handleChangeRadio = (event) => {
        setHasAccess(event.target.value);
        if (event.target.value === "No") {
            setHasAccessTag(true);
        }
        if (event.target.value === "Yes") {
            setHasAccessTag(false);
        }
    };

    const [adm, setAdms] = React.useState([]);
    const [listNum, setListNum] = React.useState([]);
    const [listAdm, setListAdm] = React.useState([]);
    const [numSelected, setNumSelected] = React.useState([]);


    const [file, setFile] = React.useState();
    const [imageGroup, setImageGroup] = React.useState();
    const [description, setDescription] = React.useState();

    const [imageGroupEdited, setImageGroupEdited] = React.useState();

    /* Atualiza o value dos Sliders */
    const [valueGroup, setValueG] = React.useState(100);
    const handleChangeG = (event, newValue) => {
        setValueG(newValue);
    };
    const handleChangeGInput = (newValue) => {
        setValueG(newValue);
    };
    const [valuePeople, setValueP] = React.useState(500);
    const handleChangeP = (event, newValue) => {
        setValueP(newValue);
    };
    const handleChangePInput = (newValue) => {
        setValueP(newValue);
    };
    const [valueInitial, setValueI] = React.useState(1);
    const handleChangeI = (event, newValue) => {
        setValueI(newValue);
    };
    const handleChangeIInput = (newValue) => {
        setValueI(newValue);
    };

    /*  const checkPermission = () => {
         let checkbox = document.getElementById('autoGroup');
         if (checkbox.checked) {
             setPermision(true)
         } else {
             setPermision(false)
         }
     } */

    function loadNum() {
        axios_base.get("/number").then(res => {
            var info = res.data;
            setListNum(info);
        });
    }

    const getAdmList = async (id) => {
        try {
            const {data: res} = await axios_base.get(`/number/${id}/contacts`);
            setListAdm(res);
        } catch (error) {
            console.log(error.response);
        }
    };

    const loadAdms = async (id) => {
        try {
            setNumSelected(id);
            setNum(id.value);
            const {data: res} = await axios_base.get(`/number/${id.value}/status`);
            getAdmList(id.value);
            setDisconnectedEdit(false);
        } catch (error) {
            setModal2(true);
        }
    };

    React.useEffect(() => {
        loadNum();
    }, []);

    const CreateCampaing = async (campaignName, adm, GruoupName, description, imageGroup, valueGroup, valuePeople, valueInitial, valuePermission, facebookPixelEventName, facebookPixelId) => {
        if (valuePeople <= adm.length + 1) {
            setOpen2(true);
        } else {
            if (!valueGroup || !valuePeople || !valueInitial || !num || !campaignName || !GruoupName || !description || !imageGroup) {
                setOpenError(true);
            } else {
                try {
                    setModal3(!modal3);
                    setOpenCreate(true);
                    const createdCampaign = await CampaignFunctions.createCampaignFunction(num, campaignName, GruoupName, description, imageGroup, valueGroup, valuePeople, valueInitial, valuePermission, adm, localStorage.getItem("token"), facebookPixelEventName, facebookPixelId);
                    setOpenCreate(false);
                    setOpen(true);
                    toggle();
                    history.push(`/campaign/${createdCampaign.id}/detail`);
                } catch (error) {
                    if (error?.response?.data?.message === "GroupDescription max") {
                        setOpenCreate(false);
                        setModal3(false);
                        setErrorDescription(true);
                    } else {
                        setOpenCreate(false);
                        setModal3(false);
                        setOpenError2(true);
                    }

                }

            }

        }
    };

    const saveEditCampaing = async () => {
        try {
            setOpenCreate(true);
            const editCampaign = await CampaignFunctions.editCampaignFunction(num, campaignName, GruoupName, description, imageGroupEdited, valueGroup, valuePeople, valueInitial, valuePermission, adm, localStorage.getItem("token"), id, hasAccessTag, facebookPixelEventName, facebookPixelId);
            if (!editCampaign || !editCampaign?.name) {
                setOpenCreate(false);
                setOpenError2(true);
            } else {
                setOpenCreate(false);
                setOpen(true);
                setModaEdit(true);
                setTimeout(() => {
                    toggle();
                    reload();
                    history.push(`/campaign/${id}/detail`);
                    setModaEdit(false);
                }, 4000);
                ;
            }
        } catch (error) {
            console.log(error);
        }
    };

    let optionsNumbers = listNum.map(number => ({
        label: formatPhoneNumberIntl("+" + number.number.split("@")[0]),
        value: number.id,
    }));
    let options = listAdm?.map(contacts => ({
        label: `${contacts.name} (${formatPhoneNumberIntl("+" + contacts.wid.split("@")[0])})`,
        value: contacts.wid,
    })).sort((a, b) => a.label.localeCompare(b.label));

    const toggle = () => {
        setDescription();
        setImageGroup();
        setNameGroup();
        setName();
        setValueP(235);
        setValueG(100);
        setValueI(1);
        setNumSelected();
        setActiveStep(0);
        setModal(!modal);
        setFile();
        setImageGroupEdited();
        setModal3(false);
        setHasAccessTag(false);
        setDisconnectedEdit(false);
    };
    const toggle3 = () => {
        setModal2(!modal2);
        setModal(!modal);
    };

    const toggle4 = () => {
        setModal3(!modal3);
    };

    const toggleChangeNumber = () => {
        setModalChangeNumber(!modalChangeNumber);
        setHasAccess("");
    };
    const toggleEdit = () => {
        setModaEdit(!modaEdit);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenCreate(false);
        setOpenError(false);
        setOpenError2(false);
        setOpen(false);
        setOpen2(false);
        setErrorSize(false);
        setErrorDescription(false);

    };

    const handleChangeImg = (event) => {
        if (event.target.files.length === 0) {
            return;

        }
        var converted = event.target.files[0].size / (1024 * 1024);
        var roundTo = null;
        roundTo = converted.toFixed(roundTo);

        if (roundTo > 1) {
            event.target.value = null;
            setErrorSize(true);
        } else {
            setImageGroup(event.target.files[0]);
            setImageGroupEdited(event.target.files[0]);
            setFile(URL.createObjectURL(event.target.files[0]));
        }
    };

    // Steps 
    function getSteps() {
        return [`${t("words.campaign_settings")}`, `${t("words.creat_group")}`, `${t("words.group_settings")}`, `${t("words.group_settings")}`];
    }

    const [activeStep, setActiveStep] = React.useState(1);
    const steps = getSteps();

    const handleNext = () => {
        if (activeStep === 0) {
            if (!campaignName || !num && action !== "edit") {
                if (!campaignName) {
                    ReactDOM.render(<spam style={{
                        color: "red",
                        fontSize: "12px",
                    }}>* {t("words.required")}</spam>, document?.getElementById("statusName"));
                    setOpenError(true);
                }
                if (!num) {
                    ReactDOM.render(<spam style={{
                        color: "red",
                        fontSize: "12px",
                    }}>* {t("words.required")}</spam>, document?.getElementById("statusNumber"));
                    setOpenError(true);
                }
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        if (activeStep === 1) {
            if (action !== "edit") {
                if (!GruoupName || !num || !description || !imageGroup || description.length > 512) {
                    if (!GruoupName) {
                        ReactDOM.render(<spam style={{
                            color: "red",
                            fontSize: "12px",
                        }}>*{t("words.required")}</spam>, document?.getElementById("statusNameGroup"));
                        setOpenError(true);
                    }
                    if (!description) {
                        ReactDOM.render(<spam style={{
                            color: "red",
                            fontSize: "12px",
                        }}>*{t("words.required")}</spam>, document?.getElementById("statusDescription"));
                        setOpenError(true);
                    }
                    if (!imageGroup) {
                        ReactDOM.render(<spam style={{
                            color: "red",
                            fontSize: "12px",
                        }}>*{t("words.required")}</spam>, document?.getElementById("statusImage"));
                        setOpenError(true);
                    }
                    if (description?.length > 512) {
                        ReactDOM.render(<spam style={{
                            color: "red",
                            fontSize: "12px",
                        }}>*{t("words.description_max_length")}</spam>, document?.getElementById("statusDescription"));
                        setOpenErrorLength(true);
                    }

                } else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        if (activeStep === 2) {
            if (valuePeople <= adm.length + 1) { //Não deixa criar um grupo onde o numero de pessoas permitidas seja menor que a quantidade de adm
                setOpen2(true);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        if (activeStep > 2) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const editCampaign = async () => {
        if (action === "edit") {
            try {
                if (!numberStatus) {
                    setActiveStep(0);
                    setChangeNumber(true);
                    setHasAccessTag(true);
                }
                const {data: res} = await axios_base.get(`/number/${campaignInfo.number_id}/status`);
                loadAdms(({label: campaignNum, value: campaignInfo.number_id}));
            } catch (error) {
                setDisconnectedEdit(true);
            }
            setModal(!modal);
            if (!campaignInfo?.facebook_pixel?.id) {
                setFacebookPixel(false);
            } else {
                setFacebookPixel(true);
                setFacebookPixelEventName(campaignInfo?.facebook_pixel?.event_name);
                setFacebookPixelId(campaignInfo?.facebook_pixel?.id);
            }
            setName(campaignInfo.name);
            setNameGroup(campaignInfo.group_name);
            setDescription(campaignInfo.group_description);
            setFile(`${process.env.REACT_APP_LINK_API}/storage/${campaignInfo.group_image}`);
            setValueG(campaignInfo.group_count);
            setValueP(campaignInfo.member_count_per_group);
            setValueI(campaignInfo.initial_group_number);
            setAdms(leadsInfo?.filter(a => a.is_admin === true).map(contacts => (contacts.is_admin === true && {
                label: formatPhoneNumberIntl("+" + contacts.number?.split("@")[0]),
                value: contacts.number,
            })));
        }
    };

    const [changeNumber, setChangeNumber] = React.useState(false);

    const agreeWithChange = () => {
        setChangeNumber(true);
        setModalChangeNumber(false);
    };

    const changePixelConfig = (event) => {
        setFacebookPixel(event.target.checked);
        setFacebookPixelEventName("");
        setFacebookPixelId("");
    };
    React.useEffect(() => {
        if (!numberStatus) {
            setChangeNumber(true);
        } else {
            setChangeNumber(false);
        }
    }, [modal]);

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <DivStep className="divstep">
                    <Colunm1>
                        <TextTitle>
                            <h5>{t("words.campaign_settings")}</h5>
                            <small>{t("words.new_campaign_description")}</small>
                        </TextTitle>
                        <Line>
                            <Label htmlFor="nomeDaCampanha">{t("words.campaing_name")} <InfoTooltip
                                text={t("words.explanation_campaign_name")}/></Label>
                            <Input maxlength="35" onChange={(e) => {
                                setName(e.target.value);
                            }} type="text" value={campaignName} name="nomeDaCampanha" id="nomeDaCampanha"
                                   placeholder={t("words.campaing_name")}/>
                            <div id="statusName"></div>
                        </Line>
                        <Line>
                            <Label htmlFor="select_number">{t("words.number_campaign")} <InfoTooltip
                                text={t("words.explanation_campaign_number")}/></Label>
                            {action === "edit" ? !changeNumber ? <ButtonBlue
                                        onClick={() => toggleChangeNumber()}>{t("words.change_number_option")}</ButtonBlue> :
                                    <Select options={optionsNumbers} onChange={opt => loadAdms(opt)} hasValue
                                            value={numSelected} id="select_number"/> :
                                <Select options={optionsNumbers} onChange={opt => loadAdms(opt)} hasValue
                                        value={numSelected} id="select_number"/>}
                            <div id="statusNumber"></div>
                        </Line>
                    </Colunm1>
                    <Colunm2>
                        <InfoStepCard/>
                    </Colunm2>
                </DivStep>;
            case 1:
                return <DivStep>
                    <Colunm1>
                        <TextTitle>
                            <h5>{t("words.creat_group")}</h5>
                            <small>{t("words.configure_groups")}</small>
                        </TextTitle>
                        <Line style={{display: "flex"}}>
                            <Line>
                                <label htmlFor="contained-button-file" className="ImgLabel" props={file}
                                       style={{backgroundImage: `url(${file})`}}>
                                    <input accept="image/jpeg,image/png"
                                           style={{opacity: "0", width: "20%", height: "50%"}}
                                           id="contained-button-file" type="file" onChange={handleChangeImg}
                                           class="form-control" required/>
                                    {!file && <GroupIcon/>}
                                    <div className="CameraDiv"><CameraIcon/></div>
                                </label>
                                <div id="statusImage"></div>
                            </Line>
                            <Line style={{flex: "1", paddingLeft: "15px"}}>
                                <Label htmlFor="nomeDoGrupo">{t("words.group_Name")} <InfoTooltip
                                    text={t("words.explanation_group_name")}/></Label>
                                <Input maxlength="25" onChange={(e) => {
                                    setNameGroup(e.target.value);
                                }} type="text" value={GruoupName} name="nomeDpGrupo" id="nomeDoGrupo"
                                       placeholder={t("words.group_Name")}/>
                                <div id="statusNameGroup"></div>
                            </Line>
                        </Line>
                        <Line>
                            <Label htmlFor="admnistradores">{t("words.adm")} <InfoTooltip
                                text={t("words.explanation_group_admin")}/></Label>
                            <Select options={options} closeMenuOnSelect={false} isMulti onChange={opt => {
                                setAdms(opt);
                            }} hasValue value={adm} id="adms" isDisabled={false}/>
                            <div id="statusAdm"></div>
                        </Line>
                        <Line>
                            <Label htmlFor="descricao">{t("words.description")} <InfoTooltip
                                text={t("words.explanation_group_description")}/></Label>
                            <Input maxlength="512" onChange={(e) => {
                                setDescription(e.target.value);
                            }} rows="4" value={description} type="textarea" name="descricao" id="descricao"
                                   placeholder={t("words.description")}/>
                            <div id="statusDescription"></div>
                        </Line>
                    </Colunm1>
                    <Colunm2>
                        <InfoStepCard/>
                    </Colunm2>
                </DivStep>;
            case 2:
                return <DivStep>
                    <Colunm1>
                        <TextTitle style={{marginBottom: "20px"}}>
                            <h5>{t("words.group_settings")}</h5>
                            <small>{t("words.members_quantity")}</small>
                        </TextTitle>
                        <Line>
                            <div style={{width: "50%"}}>
                                <div style={{display: "flex"}}>
                                    <Typography id="non-linear-slider" gutterBottom>
                                        {t("words.number_groups")}
                                    </Typography>
                                    <InfoTooltip text={t("words.groups_per_campaign")}/>
                                </div>
                                <div style={{display: "flex"}}>
                                    <BoxViewNumber>
                                        <input type="number" value={valueGroup}
                                               onChange={(e) => handleChangeGInput(Math.min(e.target.value, 500))}
                                               max={500}/>
                                    </BoxViewNumber>
                                    <div style={{paddingLeft: "15px", width: "100%"}}>
                                        <PrettoSlider value={valueGroup} onChange={handleChangeG}
                                                      valueLabelDisplay="auto" /* disabled={disabled} */
                                                      defaultValue={100} max={500} min={1} step={1}/>
                                    </div>
                                </div>
                            </div>
                        </Line>
                        <Line>
                            <div style={{width: "50%"}}>
                                <div style={{display: "flex"}}>
                                    <Typography id="discrete-slider-always" gutterBottom>
                                        {t("words.people_Limit")}
                                    </Typography>
                                    <InfoTooltip text={t("words.people_per_group")}/>
                                </div>
                                <div style={{display: "flex"}}>
                                    <BoxViewNumber>
                                        <input type="number" value={valuePeople}
                                               onChange={(e) => handleChangePInput(Math.min(e.target.value, 500))}
                                               max={500}/>
                                    </BoxViewNumber>
                                    <div style={{paddingLeft: "15px", width: "100%"}}>
                                        <PrettoSlider valueLabelDisplay="auto" value={valuePeople}
                                                      onChange={handleChangeP} defaultValue={235} max={500} min={1}
                                                      step={1}/>
                                    </div>
                                </div>
                                <small style={{
                                    color: "#4E4E4E",
                                    opacity: "0.65",
                                    fontSize: "0.8rem",
                                }}>{t("words.lead_value_warning")}</small>
                            </div>
                            <small></small>
                        </Line>
                        <Line>
                            <div style={{width: "50%"}}>
                                <div style={{display: "flex"}}>
                                    <Typography id="discrete-slider-always" gutterBottom>
                                        {t("words.initial_number")}
                                    </Typography>
                                    <InfoTooltip text={t("words.group_identifier")}/>
                                </div>
                                <div style={{display: "flex"}}>
                                    <BoxViewNumber>
                                        <input type="number" value={valueInitial}
                                               onChange={(e) => handleChangeIInput(Math.min(e.target.value, 100))}
                                               max={100} min={1}/>
                                    </BoxViewNumber>
                                    <div style={{paddingLeft: "15px", width: "100%"}}>
                                        <PrettoSlider value={valueInitial} onChange={handleChangeI}
                                                      valueLabelDisplay="auto" defaultValue={100} min={1} step={1}/>
                                    </div>
                                </div>
                            </div>
                        </Line>
                    </Colunm1>
                    <Colunm2>
                        <InfoStepCard/>
                    </Colunm2>
                </DivStep>;
            case 3:
                return <DivStep>
                    <Colunm1>
                        <TextTitle style={{marginBottom: "20px"}}>
                            <h5>{t("words.facebook_pixel")}</h5>
                            <small>{t("words.facebook_pixel_config")}</small>
                        </TextTitle>
                        <Line style={{flex: "1"}}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        control={<BlueCheckbox color="#0294FF" onChange={changePixelConfig}
                                                               checked={facebookPixel} id="facebookPixelCheck"/>}
                                        label={t("words.facebook_pixel_integration")}
                                        labelPlacement={t("words.facebook_pixel_integration")}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Line>
                        {facebookPixel &&
                            <Line>
                                <Line style={{flex: "1"}}>
                                    <Label htmlFor="facebook_pixel_id">{t("words.facebook_pixel_id")}<InfoTooltip
                                        text={t("words.facebook_pixel_id_help")}/></Label>
                                    <Input maxlength="25" onChange={e => {
                                        setFacebookPixelId(e.target.value);
                                    }} type="text" value={facebookPixelId} name="facebook_pixel_id"
                                           id="facebook_pixel_id" placeholder={t("words.facebook_pixel_id")}/>
                                    <div id="statusNameGroup"></div>
                                </Line>
                                <Line style={{flex: "1"}}>
                                    <Label
                                        htmlFor="facebook_pixel_event_name">{t("words.facebook_pixel_event_name")}<InfoTooltip
                                        text={t("words.facebook_pixel_event_name_help")}/></Label>
                                    <Input maxlength="25" onChange={e => {
                                        setFacebookPixelEventName(e.target.value);
                                    }} type="text" value={facebookPixelEventName} name="facebook_pixel_event_name"
                                           id="facebook_pixel_event_name"
                                           placeholder={t("words.facebook_pixel_event_name")}/>
                                    <div id="statusNameGroup"></div>
                                </Line>
                            </Line>
                        }
                    </Colunm1>
                    <Colunm2>
                        <InfoStepCard/>
                    </Colunm2>
                </DivStep>;
            default:
                return "Unknown step";
        }
    }

    // Steps

    return (
        <Container>
            {action === "edit" ?
                <div onClick={editCampaign}>{buttonLabel}</div>
                :
                <ButtonBlue onClick={toggle} style={{color: "#FFFFFF"}}>{buttonLabel}</ButtonBlue>
            }
            <Modal isOpen={modal} toggle={toggle} style={{
                maxWidth: window.innerWidth < "1920" ? "70vw" : "55vw",
                background: "#F3F7FF",
                boxShadow: "0px 14px 32px #00000040",
                borderRadius: "10px",
            }}>
                <Top>
                    <Div style={{display: "flex", justifyContent: "space-between", paddingBottom: "2%"}}>
                        {action === "edit" ? <h3>{t("words.edit_campaign")}</h3> : <h3>{t("words.create_camp")}</h3>}
                        <img alt="" src={Cloese} style={{cursor: "pointer"}} onClick={toggle}/>
                    </Div>
                    <TopBar style={{}}>
                        <Div>
                            <Stepper style={{width: "90%", height: "90%", background: "transparent"}} alternativeLabel
                                     activeStep={activeStep} connector={<ColorlibConnector/>}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}
                                                   style={{paddingRight: "10px", paddingLeft: "10px"}}></StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Div>
                    </TopBar>
                </Top>
                <Body>
                    <BodyCard>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography>
                                    {t("words.done")}
                                </Typography>

                            </div>
                        ) : (
                            <div>
                                <Typography>{getStepContent(activeStep)}</Typography>
                                <div style={{textAlign: "center", marginTop: "25px"}}>
                                </div>
                            </div>
                        )}
                    </BodyCard>
                </Body>
                <ModalFooter style={{paddingRight: "60px", background: "#F3F7FF", borderTop: "0"}}>
                    <div>
                        <Button onClick={toggle} style={{
                            fontFamily: "Lato",
                            fontWeight: "bold",
                            color: "#5A5959",
                        }}>{t("words.cancel")}</Button>
                    </div>
                    {activeStep >= 1 &&
                        <div>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={btn.root}
                                    style={{color: "#4E4E4E", background: "#fff", boxShadow: "0px 1px 2px #00000029"}}>
                                {t("words.back")}
                            </Button>
                        </div>}
                    <div>
                        {action === "edit" && hasAccessTag ? <Button
                                onClick={() => saveEditCampaing()}
                                className={BtnSave.root}
                            >
                                {t("words.edit")}
                            </Button> :
                            <Button
                                onClick={activeStep === steps.length - 1 ? () => action === "edit" ? saveEditCampaing() : CreateCampaing(campaignName, adm, GruoupName, description, imageGroup, valueGroup, valuePeople, valueInitial, valuePermission, facebookPixelEventName, facebookPixelId) : handleNext}
                                className={activeStep === steps.length - 1 ? BtnSave.root : btn.root}
                            >
                                {activeStep === steps.length - 1 ? action === "edit" ? `${t("words.edit")}` : `${t("words.save")}` : `${t("words.next")}`}
                            </Button>}

                    </div>
                </ModalFooter>
                <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        {t("words.created_campaign")}!
                    </Alert>
                </Snackbar>
                <Snackbar open={openCreating} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="info">
                        {t("words.creating")}
                    </Alert>
                </Snackbar>
                <Snackbar open={openError} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {t("words.fields_empty")}
                    </Alert>
                </Snackbar>
                <Snackbar open={openError2} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {action === "edit" ? `${t("words.unable_complete")}` : `${t("words.unable_create_campaign")}`}

                    </Alert>
                </Snackbar>
                <Snackbar open={open2} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {t("words.error_number_people")}
                    </Alert>
                </Snackbar>
                <Snackbar open={errorSize} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {t("words.error_image_size_1mb")}
                    </Alert>
                </Snackbar>
                <Snackbar open={errorDescription} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {t("words.desc_length_error")}
                    </Alert>
                </Snackbar>
                <Snackbar open={disconnectedEdit} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {t("words.disconnected")}!
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorLength} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {t("words.description_alert_length")}!
                    </Alert>
                </Snackbar>
            </Modal>

            <Modal isOpen={modal2} toggle={toggle3}
                   style={{maxWidth: "770px", borderRadius: "6px", padding: "20px 20px"}}>
                <div style={{
                    display: "flex",
                    alignContent: "right",
                    alignItems: "right",
                    justifyContent: "right",
                    padding: "10px",
                    cursor: "pointer",
                }}>
                    <img alt="close" src={Close} onClick={toggle3}/>
                </div>
                <div style={{textAlign: "center", paddingTop: "10px"}}>
                    <h1 style={{
                        font: "normal normal bold 2rem Mulish",
                        letterSpacing: "0px",
                        color: "#373F4E",
                        opacity: "1",
                        textTransform: "uppercase",
                    }}>{t("words.attention")}</h1>
                    <h3 style={{
                        font: "normal normal bold 1.8rem Mulish",
                        letterSpacing: "0px",
                        color: "#4E4E4E",
                        opacity: "1",
                    }}>{t("words.alert_disconnected")}</h3>
                </div>
                <ModalBody>
                    <div style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: "40px",
                    }}>
                        <img alt="Atention" src={Illustration_Attention}/>
                    </div>
                    <div style={{
                        textAlign: "left",
                        font: "normal normal bold 1rem Mulish",
                        letterSpacing: "0px",
                        color: "#4E4E4E",
                        opacity: "1",
                    }}>
                        <p>{t("words.inform_2")}</p>
                    </div>
                </ModalBody>
                <div style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "25px 0",
                }}>
                    <Link to="/numbers" style={{textDecoration: "none"}}><Button
                        className={btn.root}>{t("words.my_numbers")}</Button></Link>
                </div>
            </Modal>

            <Modal isOpen={modal3} toggle={toggle4}
                   style={{maxWidth: "654px", borderRadius: "6px", padding: "20px 20px"}}>
                <ModalBody style={{height: "629px"}}>
                    <div style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: "40px",
                    }}>
                        <img alt="Atention" src={IllustrationLoadingtheSetting} width={544} height={335}/>
                    </div>
                    <div style={{width: "100%", justifyContent: "center", display: "flex", margin: "auto"}}>
                        <LiquidLoader/>
                    </div>
                    <div style={{
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                        margin: "auto",
                        textAlign: "left",
                        font: "normal normal 700 1.8rem Mulish",
                        letterSpacing: "0px",
                        color: "#4E4E4E",
                        opacity: "1",
                    }}>
                        {t("words.loading_settings")}
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalChangeNumber} toggle={toggleChangeNumber}
                   style={{borderRadius: "6px", padding: "20px 20px"}}>
                <ModalBody style={{borderRadius: "6px", background: "#F3F7FF"}}>
                    <h3 style={{
                        textAlign: "left",
                        font: "normal normal bold 1.4rem Mulish",
                        letterSpacing: "0px",
                        color: "#434343",
                        textTransform: "uppercase",
                        opacity: "1",
                    }}>
                        {t("words.attention")}
                    </h3>
                    <div style={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "1px solid #D0DAED",
                        borderRadius: "3px",
                        opacity: "1",
                        padding: "10px",
                    }}>
                        <small style={{
                            display: "block",
                            textAlign: "center",
                            fontSize: "1rem",
                            color: "#4E4E4E",
                            lineHeight: "1.8rem",
                        }}>
                            {t("words.change_number")}
                        </small>
                    </div>

                    <FormControl component="fieldset" style={{paddingTop: "15px"}}>
                        <FormLabel component="legend" style={{color: "#4E4E4E"}}>{t("words.has_access")}</FormLabel>
                        <RadioGroup aria-label="access" style={{display: "flex", flexDirection: "row"}} name="access"
                                    value={hasAccess} onChange={handleChangeRadio}>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>} label={t("words.yes")}/>
                            <FormControlLabel value="No" control={<Radio color="primary"/>} label={t("words.No")}/>
                        </RadioGroup>
                    </FormControl>

                    <div style={{display: "flex", justifyContent: "flex-end", paddingTop: "10px"}}>
                        <div style={{padding: "5px"}}><Button onClick={toggleChangeNumber} style={{
                            fontFamily: "Lato",
                            fontWeight: "bold",
                            color: "#5A5959",
                        }}>{t("words.cancel")}</Button></div>
                        <div style={{padding: "5px"}}>
                            {hasAccess === "No" || hasAccess === "Yes" ? <Button onClick={agreeWithChange}
                                                                                 className={activeStep === steps.length - 1 ? BtnSave.root : btn.root}>
                                {t("words.agree_chenge_number")}
                            </Button> : <Button onClick={agreeWithChange}
                                                className={activeStep === steps.length - 1 ? BtnSave.root : btn.root}
                                                disabled>
                                {t("words.agree_chenge_number")}
                            </Button>}
                        </div>
                    </div>
                </ModalBody>
            </Modal>


            <Modal isOpen={modaEdit} toggle={toggleEdit} style={{borderRadius: "6px"}}>
                <ModalBody style={{background: "#F3F7FF", borderRadius: "6px"}}>
                    <h3 style={{
                        textAlign: "left",
                        font: "normal normal bold 1.2rem Mulish",
                        color: "#434343",
                        textTransform: "uppercase",
                        opacity: "1",
                    }}>{t("words.all_right")}.</h3>
                    <p style={{
                        textAlign: "left",
                        font: "normal normal 600 1rem Mulish",
                        color: "#585858",
                        opacity: "1",
                        paddingTop: "15px",
                    }}>{t("words.async_edit")}</p>
                    <div style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "25px 0",
                    }}>
                        <Button className={btn.root} onClick={() => setModaEdit(false)}>{t("words.close")}</Button>
                    </div>
                </ModalBody>
            </Modal>


        </Container>
    );
};

export default StepByStep;